
import React, { useRef, useState, useLayoutEffect, useReducer } from "react";
import "./userDetails.css";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import uploadFileToAWS from "../../helpers/uploader";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { format } from "date-fns";
import ReactModal from "react-modal";
import Camera from "../../Assets/camera.svg";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import LinearProgress from "@mui/material/LinearProgress";
import { Typography, Box, CircularProgress } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import imageCompression from "browser-image-compression";
// import request from "request";
import { useNavigate } from "react-router-dom";
import logo from '../../Assets/logo.png';

export default function UserDetailsForm() {
  const { studioRef } = useParams();
  const navigate = useNavigate();
  const toastId = useRef(null);
  const [isOpen, setIsOpen] = useState(true);
  const [valid, setValid] = useState(null);
  const [folderName] = useState(format(new Date(), "dd-MM-yyyy"));
  const [selfie, setSelfie] = useState("");
  const [selfieError, setSelfieError] = useState(null);
  const [loading, setLoading] = useState(0);
  const [value, setValue] = useState();
  const [mobileError, setMobileError] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "30px",
    },
  };

  const getRefData = async () => {
    let userData = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/user/${studioRef}`
    );
    if (userData && userData?.data?.email) {
      setValid(true);
    } else {
      // await runOtp();
      setValid(false);
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.warning("Invalid Url");
      }
    }
  };

  useLayoutEffect(() => {
    getRefData();
    //eslint-disable-next-line
  }, [studioRef]);

  const {
    register,
    reset,
    formState: { errors, isDirty },
    handleSubmit,
    control,
  } = useForm();

  const mimeTypes = ["image/tiff", "image/png", "image/jpg", "image/jpeg"];

  const onSubmitPhoto = async (e) => {
    setLoading(0);
    e.target.files[0] && setLoading((prev) => prev + 10);
    const filePD = e.target.files[0];

    try {
      const options = {
        maxSizeMB: 1.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(filePD, options);

      const contentType = mimeTypes.includes(compressedFile?.type)
        ? compressedFile?.type
        : null;
      if (!compressedFile || !contentType) {
        return;
      }
      setLoading((prev) => prev + 20);
      const form = new FormData();
      form.append("file", compressedFile);
      const nameArr = compressedFile.name.split(".");
      const filePDName =
        Date.now() +
        "-" +
        nameArr[0].replace(/[^0-9a-zA-Z.]/g, "_") +
        "." +
        nameArr[1];
      const saved = await uploadFileToAWS(
        folderName,
        compressedFile,
        filePDName,
        contentType,
        setLoading
      );
      if (saved?.status === 200) {
        let fileUrl = `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${folderName}/${filePDName}`;
        setSelfie(fileUrl);
        setSelfieError(false);
        setLoading((prev) => prev + 20);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitData = async (data) => {
    setLoading(0);
    const { name } = data;

    if (!selfie) {
      const addToSecret = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/no-selfie`,
        {
          name,
          mobile: value,
          referenceid: studioRef,
        }
      );
      setSelfieError(true);
    } else {
      if (!value || !isValidPhoneNumber(value.toString())) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.warning("WhatsApp Number is Invalid");
        }
        setMobileError(true);
      } else {
        const addTo = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/user/photo-req`,
          {
            name,
            mobile: value,
            selfie,
            referenceid: studioRef,
          }
        );
        if (addTo.data && addTo.data.status === "success") {
          setValue();
          setSelfie("");
          setMobileError(false);
          reset({ name: "" });
          // setTimeout(() => {
          //   window.close();
          // }, 2000);
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(addTo.data.message);
          }
          navigate("/thankyou");
        } else if (addTo.data.status === "sms") {
          navigate(`/otp-verify/${addTo?.data?.insertId}`);
        }
      }
    }
  };

  return (
    <>
      <section>
        <div className="wrapper">
          <ReactModal
            isOpen={isOpen}
            style={customStyles}
            contentLabel="My dialog"
          >
            {valid ? (
              <div className="form-container">
                <img src={logo} style={{width: "100px", margin: "10px 80px"}} />
                <h3>
                  <span> Let's Get Your Image</span>
                </h3>
                <label className="userdetail-label">Upload your Selfie</label>
                <div className="selfie-container">
                  {loading !== 0 && loading < 100 ? (
                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress variant="determinate" value={loading} />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          color="text.secondary"
                        >
                          {`${Math.round(loading)}%`}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <label
                      className="selfie-btn"
                      htmlFor="selfie"
                      style={{ cursor: "pointer" }}
                    >
                      <img src={Camera} alt="camera" className="camera_img" />
                      Take a Selfie *
                    </label>
                  )}
                  <input
                    required
                    className="userdetail-file-input"
                    type="file"
                    accept="image/*"
                    capture="user"
                    id="selfie"
                    onChange={onSubmitPhoto}
                    style={{ display: "none" }}
                  />
                  {selfieError && !selfie && !loading && (
                    <p style={{ color: "#ff0000" }}>
                      Please Upload Selfie here
                    </p>
                  )}

                  {selfie && (
                    <p
                      style={{
                        color: "#00BA61",
                        marginLeft: "10px",
                        fontWeight: "700",
                      }}
                    >
                      Uploaded
                    </p>
                  )}
                </div>

                <form
                  className="userdetail-form"
                  onSubmit={handleSubmit(submitData)}
                >
                  <label htmlFor="name" className="userdetail-label">
                    Name *
                  </label>
                  <input
                    className="userdetail-input"
                    type="text"
                    placeholder="Name"
                    id="name"
                    {...register("name", {
                      required: true,
                      pattern: {
                        value: /^[a-zA-Z\s]*$/,
                      },
                    })}
                  />
                  <p style={{ color: "#ff0000" }}>
                    {errors.name && "Please enter valid name"}
                  </p>
                  <>
                    <label className="userdetail-label" htmlFor="mobile">
                      WhatsApp Number *
                    </label>
                    <PhoneInput
                      defaultCountry="IN"
                      placeholder="Enter phone number"
                      className="userdetail-input"
                      value={value}
                      onChange={setValue}
                    />
                    <p style={{ color: "#ff0000" }}>
                      {value &&
                        !isValidPhoneNumber(value.toString()) &&
                        "Please enter valid Mobile Number"}
                    </p>
                  </>
                  <button type="submit" className="userdetail-button">
                    Submit
                  </button>
                </form>
              </div>
            ) : (
              <>
                <p className="text-color">Loading ... </p>
              </>
            )}
          </ReactModal>
        </div>
      </section>
    </>
  );
}
